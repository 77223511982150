export default {
  route: {
    Dashboard: '儀表板',
    SportsDataCharityService: '運動數據公益平台',
    Specification: '開發指引',
    JoinPlatform: '加入平台',
    SiteMap: '網站導覽',
    FAQ: '常見問題',
    ContactUs: '聯絡我們',
    Privacy: '隱私權政策',
    Terms: '服務條款',
    AboutProject: '平台政策背景',
    DataUsage: '資料使用說明',
    UseCase: '公益案例',
    CheckUpload: '檢查上傳',
    Login: '登入',
    SearchData: '資料查詢',
    QA: '串接常見問題',
    SyntheticData: '已處理資料查詢',
    UploadFile: '上傳檔案',
    Basic: '基本資料',
    Password: '變更密碼',
    Token: 'Access Token',
    Account: '帳號管理',
    Organization: '組織管理',
    Consumer: '運用者審核',
    Device: '分散式裝置管理',
    DeviceList: '用戶端設備清單',
    Activities: '操作紀錄',
    UserRecord: '使用者紀錄',
    DataLog: '資料處理紀錄',
    DataSpec: '數據規格',
    Dataset: '數據集',
    Achievements: '數據案例',
    Sdcamp: '2024實證徵案報名',
    PlatformUpdates: '更新公告',
    // device
    deviceLogin: '登入',
    deviceApply: '申請'
  },
  Dashboard: {
    SportsDataCharityService: '運動數據公益平台',
    usefulData: '可運用資料累積',
    Records: '筆',
    PhysiologyData: '生理資料',
    SportsData: '運動資料',
    PhysicalFitnessData: '體適能資料',
    OmnibearingSportsData: '全方位運動資料',
    MDataIntegration: '多層級資料整合',
    Overview: '總覽',
    DataTypeRecords: '資料分類資料筆數',
    DataTypeRatio: '資料分類占比',
    UploadDataRatio: '上傳資料占比',
    UploadCityRatio: '上傳縣市占比',
    UploadDataRecords: '上傳資料筆數',
    MainDataTypeRecords: '主類型資料筆數',
    PlaceTypeDataRatio: '場域類型占比',
    PlaceTypeDataRecords: '場域資料筆數',
    SportTypeDataRecords: '運動類型資料筆數',
    TypeDataRecords: '主類型資料占比',
    organization_name: '公司/組織',
    LessThanOne: '數據占比小於 1% ，期待您的更多貢獻！',
    org_city: '縣市',
    table: '表格',
    chart: '圖表',
    total: '總數',
    Physiology: '生理',
    Sport: '運動',
    PhysicalFitness: '體適能',
    no: '項次',
    Place: '場域',
    Type: '類型',
    // provider
    Thanks: '謝謝您',
    DataProvide: '數據貢獻累計',
    WelcomeProviderGreeting: '歡迎加入運動數據公益平台，期待您的數據貢獻！',
    
    MainTypeRatio: '主類型占比',
    UploadMainType: '上傳主類型種類',
    ServiceMainType: '平台主類型種類',
    PlaceTypeRatio: '場域類型占比',
    UploadPlaceType: '上傳場域種類',
    ServicePlaceType: '平台場域種類',
    Amount: '數量',
    ContributeCount: '貢獻筆數',
    ContributePercentage: '貢獻累計占比',
    ContributeDataCount: '貢獻數據數',
    ServiceDataCount: '平台數據數',
    UploadingProgressTitle: '即時資料傳輸狀態',
    UploadingProgress: '待傳輸筆數',
    CommissionedBy: '執行單位：財團法人資訊工業策進會',
    // Consumer
    UploadDataTimes: '串接次數累計',
    CumulativeNumberOfDataProviders: '累計公益捐贈者數',
    NumberOfDataProviders: '公益捐贈者數'
  },
  Chart: {
    DataTypeRatio: '三種數據占比',
    Chart_Physiology_Place: '生理數據場域',
    Chart_PhysicalFitness_Place: '體適能數據場域',
    Chart_PhysicalFitnessType: '體適能數據類型',
    Chart_Sport_Place: '運動數據場域', // dashboard
    Chart_Place: `運動數據場域`, // landing
    Chart_Sport: `運動數據類型`,
    Chart_Gender: `貢獻者性別分布`,
    Chart_City: `貢獻者來源縣市`,
    Chart_Place_desc: `不同活動場所的數據筆數，包括學校、社區、醫院、職場、衛生單位、運動中心、健身房、其他機構、戶外場所，以及居家環境等`,
    Chart_Sport_desc: `運動數據規格中針對不同運動所收集的數據。例如羽球、步行、桌球、健行、棒球、游泳、虛擬跑步、虛擬騎車、跑步、騎車等數據`,
    Chart_Gender_desc: `貢獻數據之數據當事人的性別比例`,
    Chart_City_desc: `貢獻數據之數據當事人來自哪個縣市`
  },
  LandingStatistic: {
    org_count: '串接業者數',
    place_type: '場域類型數',
    device_type: '設備類型數',
    type: '主類型數',
    org_count_desc: '與平台進行數據串接的合作業者數量',
    place_type_desc: '各種不同活動場所的分類。包括學校、社區、醫院、職場、衛生單位、運動中心、健身房、其他機構、戶外場所，以及居家環境等',
    device_type_desc: '紀錄數據的設備品項種類',
    type_desc: '運動數據規格中，定義的運動類型數目'
  },
  LandingChart: {
    female: '女',
    male: '男',
    unknow: '未提供'
  },
  general: {
    PhysiologyRawData: '生理數據',
    SportsRawData: '運動數據',
    PhysicalFitnessRawData: '體適能數據',
    Datatype: '數據類型',
    LastUpdateDate: '最後更新日',
    Update: '更新',
    Sports: '運動',
    Sport: '運動',
    PhysicalFitness: '體適能',
    Physiology: '生理',
    BasicInfo: '基本資料',
    PleaseInput: '請輸入',
    Title: '標題',
    Add: '新增',
    Edit: '編輯',
    Action: '操作',
    Index: '項次',
    Delete: '刪除',
    Remove: '移除',
    Clean: '清除',
    Batch: '批次',
    Account: '帳號',
    Password: '密碼',
    Login: '登入',
    Email: '信箱',
    ContactName: '聯絡人',
    Phone: '聯絡電話',
    ReLogin: '重新登入',
    Continue: '繼續使用',
    SeeMore: '查看更多',
    Visitors: '累積訪客數',
    Close: '關閉',
    Success: '成功',
    Warning: '警告',
    Error: '錯誤',
    Create: '產生',
    Active: '啟用中',
    Confirm: '確認',
    Save: '儲存',
    Be: '成為',
    Notify: '提示',
    Cancel: '取消',
    Filter: '篩選',
    Select: '選擇',
    Download: '下載',
    Sample: '範例',
    DownloadSample: '下載範例',
    Copy: '已複製',
    Apply: '申請',
    ApplyNow: '立刻申請',
    NoData: '暫無資料',
    oldPwd: '原密碼',
    newPwd: '新密碼',
    confirmPwd: '新密碼',
    ChangeDatatype: '切換類型',
    SearchPlaceholder: '請輸入使用者ID',
    Code: '驗證碼',
    VerificationCode: '驗證碼',
    ChangeVerificationCode: '換驗證碼',
    VerificationCodeEmpty: '驗證碼不得為空',
    ChangePasswordTitle: '請更換密碼',
    ForgetPassword: '忘記密碼',
    Register: '申請',
    RegisterNow: '立刻申請',
    ContactUs: '與我們聯繫',
    DownloadManual: '下載手冊',
    PageNotFound: '頁面不存在',
    Welcome: '加入我們'
  },
  Aria: {
    TargetBlankGotoOpenAPI: '另開新視窗前往OpenAPI',
    TargetBlankGotoDataQueryApi: '另開新視窗前往數據應用加值模組',
    TargetBlank: '另開新視窗',
    Mailto: '開啟平台聯絡信箱',
    Goto: '前往',
    SamplePic: '範例圖片',
    Page: '頁面',
    Landing: '首頁',
    UnCheckTerms: '未閱讀使用條款',
    TermsChecked: '已閱讀使用條款',
    ReadInformation: '查看說明'
  },
  imgAlt: {
    HowToUseSportData: '運動資料使用說明'
  },
  RuleMsg: {
    PleaseInput: '請輸入',
    PleaseSelect: '請選擇',
    Invalid: '無效的',
    Member: '@:Member.Member',
    Email: '信箱',
    memberName: '使用者名稱',
    Password: '密碼',
    Description: '描述',
    InputNewPwd: '請輸入新密碼',
    InputRepeatPwd: '請再次輸入密碼',
    PasswordLimit: '密碼不可少於 8 個字元',
    WarnVerificate: '驗證碼錯誤',
    PasswordNotSame: '密碼不相同',
    DifferentNewPassword: '新密碼不可與舊密碼相同'
  },
  navbar: {
    admin: '管理',
    provider: '提供',
    consumer: '運用',
    logOut: '登出',
    syslog: '系統紀錄',
    home: '首頁',
    sysActivities: '使用者操作紀錄',
    dataProcessinglog: '資料處理紀錄',
    AccountManagement: '設定',
    Activities: '個人操作紀錄',
    Dashboard: '儀錶板',
    Data: '資料使用',
    Spec: '資料規格',
    DataSpec: '數據規格',
    OpenAPILink: 'OpenAPI',
    DataQueryApi: '數據應用加值模組',
    Privacy: '隱私權政策',
    FAQ: '常見問題',
    QA: '串接常見問題',
    Introduce: '數據規格介紹',
    DataConsumer: '數據運用',
    DataProvider: '數據提供',
    DataData: '數據資源',
    UseCase: '數據案例',
    Dataset: '數據集',
    DataExplore: '平台數據',
    News: '最新消息',
    Terms: '服務條款',
    ContactUs: '聯絡我們',
    BasicInfo: '資料基本類型',
    CheckFile: '上傳檢查',
    UploadFile: '資料上傳',
    DataList: '上傳資料查詢',
    SyntheticData: '已處理資料查詢',
    ConsumerData: '平台數據',
    SiteMap: '網站導覽',
    Guidelines: '開發指引',
    Others: '其他資訊',
    Manual: '使用手冊',
    Compliance_handbook: '法遵手冊',
    About: '關於我們',
    AboutProject: '平台政策背景',
    DataUsage: '資料使用說明',
    Provider: '數據提供者',
    DeviceList: '用戶設備清單',
    Important: '重要',
    Sdcamp: '2024實證徵案報名'
  },
  File: {
    AddFile: '新增檔案',
    Upload: '上傳',
    Id: '檔案編號',
    Download: '下載',
    DragFilesHere: '或將檔案拖曳至此',
    ChooseFile: '選擇檔案',
    InputName: '請輸入，若未輸入預設為上傳檔案名稱',
    File: '檔案',
    FileSizeLimit: '20 MB，或不超過 7000 筆',
    UploadAndSave: '上傳檔案並儲存',
    MaxFileSize: '檔案大小限制',
    AllowedFileTypes: '檔案類型支援',
    ConsentForm: '串接運動數據公益平台告知事項暨個人運動資料提供同意書',
    Self_auditChecklist: '運動場域端（數據提供者）蒐集民眾運動資料之個人資料保護自我檢核表',
    Compliance_handbook: '運動數據公益平台個資評估法遵使用手冊'
  },
  Notify: {
    Added: '已新增',
    Updated: '已更新',
    Deleted: '已刪除',
    Canceled: '已取消',
    Uploaded: '已上傳',
    Created: '已建立',
    Copied: '已複製',
    TemporarySaved: '已暫存',
    Submit: '已送出',
    Saved: '已儲存',
    NoEmpty: '內容不得為空',
    Same: '內容相同，未變更。',
    pleaseInput: '請輸入',
    UpdatedReLogin: '密碼更換成功，重新登入',
    SwitchLanguage: '更換語言成功',
    SingleFileLimit: '一次只能選擇一個檔案，請先移除已選擇的檔案。',
    UnsupportedFileFormat: '無法上傳，不支援此種檔案格式。',
    FileSizeLimit: '超過可上傳的檔案大小 ({size})。',
    FileNameLimit: '檔名不可包含特殊字元。',
    LoadFail: '讀取失敗，請洽您的系統管理員。',
    UnSavedChanges: '您編輯的內容尚未儲存，確定離開嗎？',
    logoutNotifications: '您的登入已失效，請重新登入。',
    
    VerificationCodeError: '驗證碼錯誤',
    CheckLoginDuplicate: '請檢查帳號是否重複',
    Name: '請輸入名稱',
    Date: '請選擇日期',
    permission: '請選擇至少一個權限',
    // login
    lockIPAfter5Error: '15 分鐘內登入失敗超過 5 次，ＩＰ將會被鎖住',
    // register
    Authorities: '請輸入主管機關',
    ChoiceFetchType: '請至少選擇一種串接方式',
    UncheckPersoInfoAgree: '您尚未勾選個資蒐集同意書',

    DownloadSource: '數據運用請依據著作權法註明出處',
    DownloadLimit: ', 由此下載最多2000筆',

    confirmDelete: '確定@:(general.Delete)嗎？',
    confirmClose: '確定@:(general.Close)嗎？',
    confirmDeleteSth: '確定@:(general.Delete)「{name}」嗎？',
    forgetPasswordSuccess: '資料已送出，平台將儘速協助您變更密碼',
    patientWhenDeleteData: '正在刪除資料，預計將於30秒內完成，感謝您的耐心等候。'
  },
  excelUpload: {
    missing_required_fields_nums: '必填未填',
    CheckFile: '檔案格式檢查工具',
    duplicate_rows_num: '資料重複',
    total: '上傳筆數',
    success_num: '成功筆數',
    index: '資料序',
    userId: '使用者ID',
    missing_fields: '未填欄位',
    MustChoiceOne: '必擇一欄位包含',
    missing_MustChoiceOne: '必擇一欄位未填',
    checkErrorMessage: '上傳情形',
    testErrorMessage: '檢查情形',
    checkReportMessage: '查看檢查訊息',
    Important: '重要',
    checkBeforeUpload_1: '資料上傳前，建議先至：',
    checkBeforeUpload_2: '進行檢測，以有效節省您寶貴的時間',
    checkOnly: '本頁僅做資料格式檢查，不做實質上傳，若需上傳請至',
    gotoUpload: '正式上傳頁面',
    uploadTitle: '資料上傳',
    success_upload: '成功上傳',
    total_upload: '總上傳',
    missing_upload: '資料不齊全或遺漏',
    duplicate_upload: '資料重複上傳',
    correct_upload: '檢查無誤！',
    toUploadPage: '前往正式上傳頁面',
    count: '(筆數)'
  },
  PlaceType: {
    school: '學校',
    community: '社區',
    hospital: '醫院',
    workplace: '職場',
    health_unit: '衛生單位',
    sports_center: '運動中心',
    gym: '健身房',
    facility: '機構',
    outdoor: '戶外',
    home: '居家',
    openWater: '開放水域',
    unknow: '未提供'
  },
  field: {
    // basic
    version: '版本',
    main_type: '資料類型',
    type: '主類型',
    subtype: '子類型',
    main_type_zh: '資料類型中文名稱',
    type_zh: '主類型中文名稱',
    subtype_zh: '子類型中文名稱',
    user_id: '使用者ID',
    birth_year: '出生年',
    gender: '性別',
    city: '場域縣市',
    start_date: '紀錄日期時間',
    created_at: '上傳日期時間',
    timezone: '時區',
    name: '活動名稱',
    device_type: '設備類型',
    place_type: '場域類型',
    organization_name: '組織名稱',
    // place
    school: '學校',
    community: '社區',
    hospital: '醫院',
    workplace: '職場',
    health_unit: '衛生單位',
    sports_center: '運動中心',
    gym: '健身房',
    facility: '機構',
    outdoor: '戶外',
    home: '居家',
    // measure
    height: '身高',
    weight: '體重',
    waist_circumference: '腰圍',
    body_fat_percentage: '體脂率',
    body_mass_index: '身體質量指數BMI',
    skeleton_muscle_mass: '骨骼肌重SMM',
    deep_sleep_count: '深睡次數',
    light_sleep_count: '淺睡次數',
    deep_sleep_time: '深睡總時間',
    light_sleep_time: '淺睡總時間',
    achievement_length: '前彎柔軟度',
    systolic_blood_pressure: '收縮壓SBP',
    diastolic_blood_pressure: '舒張壓DBP',
    peripheral_oxyhemoglobin_saturation: '脈搏血氧飽和度SpO2',
    blood_sugar: '血糖',
    basal_metabolic_rate: '基礎代謝率BMR',
    heart_rate: '心率',
    resting_heart_rate: '安靜心率',
    average_heart_rate: '平均心率',
    max_heart_rate: '最大心率',
    heart_rate_variability: '心率變異性HRV',
    temperature: '體溫',
    respiratory_rate: '呼吸速率',
    vo2_max: '最大攝氧量',
    cardiorespiratory_fitness_index: '心肺耐力指數',
    calories: '消耗卡路里',
    distance__m: '距離(公尺)',
    distance__km: '距離(公里)',
    moving_time: '移動時間',
    elapsed_time: '花費時間',
    start_latitude: '起始緯度',
    start_longitude: '起始經度',
    end_latitude: '結束緯度',
    end_longitude: '結束經度',
    total_elevation_gain: '總爬升海拔',
    elevation_high: '最高海拔',
    elevation_low: '最低海拔',
    speed_ms: '速度(公尺/秒)',
    speed__km_hr: '速度(公里/小時)',
    average_speed__m_s: '平均速度(公尺/秒)',
    average_speed__km_hr: '平均速度(公里/小時)',
    max_speed__m_s: '最大速度(公尺/秒)',
    max_speed__km_hr: '最大速度(公里/小時)',
    ride_cadence: '踏板迴轉數',
    average_ride_cadence: '踏板平均迴轉數',
    run_cadence: '步頻',
    average_run_cadence: '平均步頻',
    kilojoules: '千焦耳',
    average_watts: '平均瓦特',
    max_watts: '最大瓦特值',
    weighted_average_watts: '權重平均瓦特值',
    steps: '步數',
    achievement_count: '達成次數',
    training_weight: '訓練重量',
    jump_height: '跳躍高度',
    upper_extremity_muscle_strength: '上肢肌力',
    lower_extremity_muscle_strength: '下肢肌力',
    total_elevation_loss: '總下降海拔',
    max_ride_cadence: '最高迴轉速',
    max_run_cadence: '最高步頻',
    average_pace__sec_km: '平均配速(秒/公里)',
    total_length_count__25m: '游泳總趟數(25公尺)',
    total_length_count__50m: '游泳總趟數(50公尺)',
    total_stroke_count: '總划水次數',
    average_distance_per_stroke: '平均划距',
    average_stroke_rate: '平均划頻',
    average_swim_pace_sec_25m: '划水平均配速(秒/25公尺)',
    average_swim_pace_sec_50m: '划水平均配速(秒/50公尺)',
    swim_swolf__25m: '游泳效率SWOLF(25公尺)',
    swim_swolf__50m: '游泳效率SWOLF(50公尺)',
    sleep_efficiency: '睡眠效率',
    route_name: '路線名稱',
    bicycle_model: '車款品牌(型號)',
    shoe_model: '鞋子品牌(型號)',
    gpx: '活動gpx檔',
    fit: '活動fit檔',
    tcx: '活動tcx檔',
    competition_event: '比賽名稱',
    competition_name: '比賽項目',
    competition_result: '比賽結果',
    competition_score: '比賽分數',
    competition_score_opposing: '比賽對手分數',
    competition_rank: '比賽名次',
    body_fat_mass: '脂肪量',
    fat_free_mass: '去脂體重FFM',
    fat_free_mass_index: '無脂肪質量指數FFMI',
    visceral_fat_index: '內臟脂肪指數VFI',
    protein: '蛋白質重',
    mineral: '無機鹽重',
    total_body_water: '身體水分TBW',
    body_age: '身體年齡',
    left_side_sbp: '左側收縮壓',
    right_side_sbp: '右側收縮壓',
    left_side_dbp: '左側舒張壓',
    right_side_dbp: '右側舒張壓',
    left_side_pulse_pressure: '左側脈壓',
    right_side_pulse_pressure: '右側脈壓',
    left_side_mean_arterial_pressure: '左側平均動脈壓',
    right_side_mean_arterial_pressure: '右側平均動脈壓',
    bat_speed: '揮棒速度',
    peak_hand_speed: '手腕速度',
    time_to_contact: '揮擊時間',
    attack_angle: '攻擊角度',
    vertical_bat_angle: '球棒垂直角度',
    on_plane_efficiency: '平面重合率',
    rotation_acceleration: '旋轉加速度',
    early_connection: '早期連結性',
    connection_at_impact: '擊球點連結性',
    power: '功率輸出',
    hitting_type: '打擊型態',
    vdot: '跑力',
    heart_rate_reserve__70: '70%儲備心率',
    heart_rate_reserve: '儲備心率',
    condition_index: '狀況指數',
    physical_fitness_index: '體能指數',
    fatigue_index: '疲勞指數',
    step_length: '步幅',
    training_volume: '訓練指數',
    running_target: '跑步目標',
    run_type: '跑步類型',
    run_training_status_source: '跑步訓練狀態指數來源',
    // badminton
    maximum_swing_strength: '最大揮拍力度',
    average_swing_strength: '平均揮拍力度',
    fastest_swing_speed: '最大揮拍速度',
    average_swing_speed: '平均揮拍速度',
    fastest_smashing_speed: '最大殺球揮拍速度',
    average_smashing_speed: '平均殺球揮拍速度',
    // table tennis
    swing_count: '揮拍計數',
    ax_mean: 'X軸加速度平均值',	
    ay_mean: 'Y軸加速度平均值',	
    az_mean: 'Z軸加速度平均值',	
    gx_mean: 'X軸角速度平均值',	
    gy_mean: 'Y軸角速度平均值',	
    gz_mean: 'Z軸角速度平均值',
    // 4MeterGait 5TimesStandAndSit BalanceTest
    gait_speed: '行走速度',	
    shoulder_angle_difference: '肩角度差',	
    hip_angle_difference: '髖角度差',	
    knee_angle_difference: '膝角度差',	
    chair_stand: '起坐秒數',
    side_by_side_stand: '雙腳並排站立',
    semi_tandem_stand: '雙腳半並排站立',
    tandem_stand: '雙腳直線站立',
    // PitchingAnalysis_0822
    ball_velocity: '投球球速(平均值)',
    total_spin: '總轉速(平均值)',
    true_spin: '真實轉速(平均值)',
    spin_direction: '旋轉方向',
    spin_efficiency: '旋轉效率',
    release_angle: '出手角度',
    horizontal_angle: '出手水平角',
    release_height: '出手高度',
    release_side: '出手水平距離',
    age: '選手年齡',
    level: '選手層級',
    // strength-training
    shoulder_press: '肩推',
    chest_press: '胸推', 
    rowing: '划船',
    back_extension: '背伸',
    back_flexion: '腹曲',
    knee_extension: '膝伸',
    knee_flexion: '膝曲',
    hip_abduction: '髖外展',
    hip_adduction: '髖內收',
    leg_press: '腿推',
    // 擊球練習
    accuracy: '準確度',
    // 灼見
    year: '年份',
    date: '日期',
    pitcher_id: '投手Id',
    pitch_throw: '投手慣用手',
    pitcher_team: '投手隊伍',
    batter_id: '打者Id',
    batter_side: '打者慣用手',
    batter_team: '打者隊伍',
    inning: '局數',
    top_bottom: '上下半局',
    balls: '壞球數',
    strikes: '好球數',
    outs: '出局數',
    on_base: '壘包狀況',
    pitch_action: '投球事件',
    pitch_type: '球路種類',
    hit_type: '擊球類型',
    hit_strength: '擊球強度',
    pa_result: '打席結果',
    kzone_y: '水平進壘點',
    kzone_z: '垂直進壘點',
    pitch_velo: '球速',
    exit_distance: '擊球距離',
    park: '球場',
    game_level: '層級',
    game_id: '賽事Id',
    game_type: '賽事類型',
    PA: '打席',
    AB: '打數',
    R: '得分',
    H: '安打',
    RBI: '打點',
    '2B': '二壘安打',
    '3B': '三壘安打',
    HR: '全壘打',
    DP: '雙殺打',
    TP: '三殺打',
    BB: '四壞球',
    IBB: '故意四壞',
    HBP: '觸身球',
    K: '三振',
    SH: '犧牲短打',
    SF: '犧牲飛球',
    FOTE: '失誤上壘',
    SB: '盜壘',
    CS: '盜壘失敗',
    pitches: '投球數',
    BF: '面對打者',
    WP: '暴投',
    BK: '投手犯規',
    runs_allowed: '失分',
    ER: '責失',
    // 高爾夫＿擊球練習_0822
    ball_speed: '球速',
    club_head_speed: '桿頭速度',
    smash_factor: '擊球效率',
    total_dist_ft: '置球點到擊球後停止滾動的距離',
    carry_dist_ft: '置球點到擊球落點的距離',
    dist_to_pin_ft: '擊球後與目標的距離',
    club_angle_path: '桿頭路徑',
    club_angle_face: '桿頭角度',
    launch_angle: '擊球仰角',
    launch_direction: '擊球方向',
    golf_back_spin: '後旋',
    golf_side_spin: '側旋',
    // baseball_投球檢測_0822
    baseball_release_time: '出手時間',
    baseball_travel_time: '飛行時間',
    baseball_side_spin: '側旋轉速',
    baseball_top_spin: '上旋轉速',
    gyro_degree: '螺旋角度',
    horizontal_break: '垂直位移',
    vertical_break: '水平位移',
    baseball_total_spin: '轉速',
    exchange_time: '轉換時間',
    pop_time: '接傳時間',
    bounces: '彈跳次數',
    sweet_spot: '最佳接觸點',
    // 跑步新增_0822
    forefoot_presure_ratio: '前腳掌壓力佔比(平均值)',
    heel_presure_ratio: '後腳掌壓力佔比(平均值)',
    linited_internal_rotation: '內旋不足',
    over_internal_rotation: '內旋過度',
    // 身體數值新增_0822
    activity_index: '活動量',
    TDEE: '總熱量消耗',
    district: '居住地行政區 ',
    OS_version: '裝置作業系統版本',
    vertical_jump: '定點跳高',
    broad_jump: '定點跳遠',
    hop: '單腳跳',
    squat: '體適能深蹲',
    isometric: '等長肌肉肌力',
    sprint: '衝刺',
    reaction_speed: '反應速度',
    // 棒式支撐
    achievement_time: '達成時間',
    // 虹映-睡眠
    rem_sleep_time: '快速動眼睡眠總時間',
    awake_time: '睡眠中醒來總時間',
    // 明根
    usage_times: '使用時數',
    total_work: '總作功',
    average_work_per_second: '每秒平均作功',
    resistance_value: '阻力值',
    setting_speed: '速度設定',
    vertical_amplitude: '垂直振幅',
    vertical_frequency: '垂直頻率',
    horizontal_amplitude: '水平振幅',
    horizontal_frequency: '水平頻率',
    frequency: '頻率',
    max_strength_push_up: '上推最大力量',
    max_strength_pull_down: '下拉最大力量',
    max_output_push_forward: '前推最大力量',
    max_output_pull_back: '後拉最大力量',
    max_output_press_inward: '內收最大力量',
    max_output_spread_outward: '外展最大力量',
    max_output_legs_extended: '上蹬最大力量',
    max_strength_front_kick: '前踢最大力量',
    max_strength_hook_kick: '勾回最大力量',
    max_output_left_rotate: '左旋最大力量',
    max_output_right_rotate: '右旋最大力量',
    max_output_bend_forward: '腹肌最大力量',
    max_output_straighten_upright: '背肌最大力量',
    back_forth_max_force_ratio: '來回最大力量比',
    max_force_body_weight_ratio_push_up: '上推最大力量體重比',
    max_force_body_weight_ratio_Pull_down: '下拉最大力量體重比',
    max_force_body_weight_ratio_push_forward: '前推最大力量體重比',
    max_force_body_weight_ratio_pull_back: '後拉最大力量體重比',
    max_force_body_weight_ratio_press_inward: '內收最大力量體重比',
    max_force_body_weight_ratio_spread_outward: '外展最大力量體重比',
    max_force_body_weight_ratio_legs_extended: '上蹬最大力量體重比',
    max_force_body_weight_ratio_front_kick: '前踢最大力量體重比',
    max_force_body_weight_ratio_hook_kick: '勾回最大力量體重比',
    max_force_body_weight_ratio_left_rotate: '左旋最大力量體重比',
    max_force_body_weight_ratio_right_rotate: '右旋最大力量體重比',
    max_force_body_weight_ratio_bend_forward: '腹肌最大力量體重比',
    max_force_body_weight_ratio_straighten_upright: '背肌最大力量體重比',
    // 虛擬划船
    standard_level: '動作標準度(%)',
    // 騎電輔車
    ebk_ass: '電動車段數',
    ebk_speed__km_hr: '電動車速度(公里/小時)',
    ebk_tor: '扭力',
    hr_sta: '心跳連結狀態',
    // 跳床
    average_hang_time:	'平均滯空時間',
    duration_trampoline:	'累計運動時間',
    // 睡眠
    average_sp_o2:	'平均血氧',
    oxygen_desaturation_3:	'血氧濃度下降指數 3%',
    oxygen_desaturation_4:	'血氧濃度下降指數 4%',
    average_pulse_rate:	'平均脈搏',
    average_respiration_rate:	'平均滯空時間',
    total_record_time:	'總記錄錄時間',
    time_in_bed:	'總在床時間',
    sleep_onset_latency:	'入睡潛伏期',
    wake_after_sleep_onset:	'睡眠中醒來總時間',
    wake_after_sleep_offset:	'睡醒後持續清醒的時間',
    total_sleep_time:	'總睡眠時間',
    T90:	'T90',
    T89:	'T89',
    T88:	'T88',
    T50:	'T50',
    T120:	'T120',
    T10:	'T10',
    T20:	'T20',
    // 游泳
    chk_pt_latitude:	'折返點緯度',
    chk_pt_longitude:	'折返點經度'
  },
  Unit: {
    cm: '公分',
    kg: '公斤',
    kg_m2: '公斤/平方公尺',
    counts: '次數',
    minutes: '分鐘',
    mmHg: '毫米汞柱',
    mg_dl: '毫克/分升',
    kilocalories: '千卡',
    beats_per_minute: '次數/每分鐘',
    milliseconds: '毫秒',
    celsius: '攝氏度',
    ml_kg_minute: '毫升/公斤/分鐘',
    sec_pulses: '運動持續時間 (秒)×100／2×(三次脈搏數總和)',
    meters: '公尺',
    kilometers: '公里',
    latitude: '緯度',
    longitude: '經度',
    meters_per_second: '公尺/秒',
    kilometers_per_hour: '公里/小時',
    revolutions_per_minute: '踏板轉動次數/分鐘',
    steps_per_minute: '步數/每分鐘',
    kilojoules: '千焦耳',
    watts: '瓦特',
    steps: '步',
    seconds_per_kilometers: '秒/公里',
    number_of_length: '趟數',
    meters_per_stroke: '公尺/每次划水',
    strokes_per_minute: '划水次數/每分鐘',
    seconds_per_25m: '秒/每25公尺',
    seconds_per_50m: '秒/每50公尺',
    SWOLF: 'SWOLF值(單趟25公尺中的划水數 + 單趟時間 (秒))',
    years_old: '歲',
    seconds: '秒',
    degrees: '度',
    percentage: '%',
    G__meter_per_second_squared: 'G值(公尺/平方秒)',
    kilo_watt: '千瓦',
    vdot: '估計值',
    index: '數值',
    newton: '牛頓(N)',
    times: '揮拍次數',	
    g: '重力加速度',
    degrees_per_second: '角度/秒',
    // new
    pt: '得分',
    cm_s: '公分/秒',
    // // PitchingAnalysis
    rpm: '轉/分',
    hh_mm: 'hh:mm',
    ft: '英尺',
    // 明根 等速肌力 律動運動
    hertz: '赫茲',
    kgf: '公斤力',
    millimeters: '公釐',
    millimeters_seconds: '公釐/秒',
    joule: '焦耳',
    gear: '段',
    nm: '牛頓米',
    bool: '是/否',
    // 睡眠
    times_hour: '次/小時'
  },
  dataList: {
    massDeleteLimit: '批次刪除筆數上限為二萬筆。',
    connectUs: '請重新設定篩選條件，或聯繫系統管理員協助刪除。',
    isDelete: '確定要刪除嗎？',
    deleteCount: '刪除筆數',
    deleteCountTotal: '刪除筆數共',
    filterCount: '篩選筆數共',
    pleaseEnter: '請於下方輸入',
    handleDeleteAction: '以進行刪除動作。',
    canNotReverse: '刪除的資料將無法復原!',
    
    enterNumber: '請输入數字',
    wrongNumber: '請輸入正確數字',
    batchDeleteTitle: '批次刪除',
    batchDeleteDescription: '「篩選」後即可使用',
    selectData: '請選擇資料類型',
    selectOrg: '請輸入組織',
    totalCount: '總筆數',
    inputType: '請輸入主類型(本項全部)',
    inputSubtype: '請輸入子類型',
    inputUserId: '請輸入使用者Id',
    uploadRange: '上傳區間 (數據上傳平台時間)',
    recordRange: '紀錄區間 (廠商紀錄的運動時間)',
    startDate: '開始日期',
    endDate: '結束日期'
  },
  mainType: {
    // Physiology_type
    BodyComposition: '身體數值',
    Sleep: '睡眠',
    // PhysicalFitness_type
    AdultPhysicalFitness: '成人體適能',
    ElderPhysicalFitness: '高齡體適能',
    SportSpecificAbility: '專項體能',
    Workout: '健身',
    WeightTraining: '重量訓練',
    StrengthTraining: '肌力訓練',
    BodyWeightTraining: '徒手訓練',
    ResistanceTraining: '阻力訓練',
    Pilates: '彼拉提斯',
    AerobicExercise: '有氧運動',
    StretchingExercise: '伸展運動',
    Crossfit: '混合健身',
    AirWalker: '滑步機',
    StairStepper: '踏步機',
    Flywheel: '飛輪',
    Treadmill: '跑步機',
    Yoga: '瑜珈',
    IsokineticMuscleStrengthTesting: '等速肌力檢測',
    IsokineticMuscleStrengthTraining: '等速肌力訓練',
    HarmonicVibrationExercise: '律動運動',
    // Sport_type
    Walk: '步行',
    Hike: '健行',
    Run: '跑步',
    VirtualRun: '虛擬跑步',
    Ride: '騎車',
    VirtualRide: '虛擬騎車',
    Golf: '高爾夫',
    Swim: '游泳',
    Elliptical: '橢圓機',
    Badminton: '羽球',
    Baseball: '棒球',
    TableTennis: '桌球',
    // 夏姿
    VirtualCanoeing: '虛擬划船',
    // 昇揚
    eBikeRide: '騎電輔車',
    // 宇康
    Trampoline: '跳床',
    // 卡洛動
    Boating: '划船'
  },
  subType: {
    LeftHandGripStrengthTest: '左手握力',
    RightHandGripStrengthTest: '右手握力',
    StepTest: '登階測驗',
    Cooper12MinuteRunTest: '十二分鐘跑走',
    PushUp: '伏地挺身',
    '1MinuteSitUp': '一分鐘屈膝仰臥起坐',
    SitAndReachTest: '坐姿體前彎',
    StandAndReachTest: '立姿體前彎',
    CounterMovementJump: '反向運動跳躍CMJ',
    '505AgilityTest': '505敏捷測試',
    IsometricMidThighPull: '等長大腿中段拉IMTP',
    '8FootUpAndGoTest': '8英呎起身繞行',
    SingleLegBalanceLeft: '30秒單腳平衡站立(左腳)',
    SingleLegBalanceRight: '30秒單腳平衡站立(右腳)',
    ChairStandTest: '30秒椅子坐站',
    '2MinuteStepTest': '2分鐘原地抬膝踏步',
    '30SecondArmCurlTest': '30秒肱二頭肌手臂屈舉',
    '4MeterGaitSpeedTest': '4公尺行走速度',
    '4MeterGaitSpeedTest(with Assistive Device)': '4公尺行走速度(使用輔具)',
    Badminton8PointAgilityTest: '米字步敏捷性測試',
    '100m': '100公尺',
    '200m': '200公尺',
    '400m': '400公尺',
    '800m': '800公尺',
    '1500m': '1500公尺',
    '3KRun': '3公里路跑',
    '5KRun': '5公里路跑',
    '10KRun': '10公里路跑',
    HalfMarathon: '半程馬拉松',
    Marathon: '全程馬拉松',
    FitnessBoxing: '拳擊',
    HighIntensityIntervalTraining: '高強度間歇',
    FatBurning: '燃脂',
    LeftLegEyeClosedSingleLegStanceTest: '閉眼單腳站立平衡(左腳)',
    RightLegEyeClosedSingleLegStanceTest: '閉眼單腳站立平衡(右腳)',
    BatSwingTest: '揮棒檢測',
    PitchingAnalysis: '投球檢測',
    // 4MeterGait 5TimesStandAndSit BalanceTest
    '4MeterGait': '四公尺行走',
    '5TimesStandAndSit': '五下起坐',
    BalanceTest: '平衡測試',
    Swing: '擊球練習',
    GamePitchByPitch: '對戰逐球',
    BatterStats: '打者成績',
    PitcherStats: '投手成績',
    CatchingAnalysis: '接傳球檢測',
    HittingAnalysis: '打擊檢測',
    CoordinationTest: '協調性測試',
    // 虹映-徒手訓練
    Lunge: '弓箭步',
    JumpingJacks: '開合跳',
    PlankHold: '棒式支撐',
    StandCrunch: '直立側腹',
    SplitSquat: '分腿蹲',
    ReverseCrunch: '下腹擺腿',
    PlankLegLift: '棒式抬腿',
    BirdDog: '鳥狗式',
    KneeinCrunch: '收膝捲腹',
    CrossKick: '交互踢腿',
    CookBridge: '橋式舉膝',
    KneeupLunge: '跨步抬膝',
    SingleLegDeadlift: '單腳硬舉',
    AlternateToeTouch: '交叉碰腳',
    PikePlank: '派克平板',
    SidePlankLegLift: '側平板抬腿',
    Burpee: '波比跳',
    Squat: '徒手深蹲',
    // 等速肌力檢測, 等速肌力訓練
    UpperLimbPush_Pull: '上肢上推下拉',
    UpperLimbFrontPush_BackPull: '上肢前推後拉',
    UpperLimbAdduction_Abduction: '上肢內收外展',
    LowerLimbAdduction_Abduction: '下肢內收外展',
    LowerLimbUpwardPush_DownwardGlide: '下肢上蹬下滑',
    LowerLimbFrontKick_BackHook: '下肢前踢後勾',
    CoreRightRotation_LeftRotation: '核心右旋左旋',
    CoreAbdominalMuscles_BackMuscles: '核心腹肌背肌',
    // 律動運動
    VerticalHarmonicVibration: '垂直律動',
    PeriodicalAcceleration: '水平律動',
    HandHarmonicVibration: '手部律動',
    // 昇揚
    ElectricBicycle: '電動輔助自行車',
    // 虹映-徒手訓練
    ShoulderCircles: '肩膀繞圈',
    SeatedKneeRaise: '後仰抬膝',
    SeatedLegRaise: '坐姿抬腿',
    SeatedHold: '坐姿停留',
    KneeRaiseClap: '抬膝拍手',
    BicepCurl: '肱二頭肌彎舉',
    TricepsExtension: '肱三頭肌伸張',
    ShoulderPress: '單手肩推',
    WeightedSquat: '負重深蹲',
    ThoracicStretch: '胸椎伸張',
    OverHeadPulldown: '過頂後拉',
    SeatedSquat: '坐姿深蹲',
    SideLunge: '側弓箭',
    KneeLiftTwist: '抬膝軀幹扭轉',
    ShuttlecockKick: '踢毽子',
    VShapedSquat: 'V字部深蹲',
    // 卡洛動-划船
    SUP: '立槳',
    Canoeing: '輕艇',
    Rowing: '西式划船'
  },
  accessToken: {
    permissionGet: 'Get 取得已上傳資料',
    permissionPost: 'Post 上傳資料',
    permissionDelete: 'Delete 刪除已上傳資料',
    fieldName: '名稱',
    fieldActive: '啟用時間',
    fieldExpired: '有效期(日)',
    fieldScope: '範圍',
    description: '備註',
    created: '已產生完畢',
    saveWarning: '請馬上複製留存，因為你將不會再看見它了',
    getToken: '產生'
  },
  accountManagement: {
    add: '新增',
    org: '組織',
    sidebarBasic: '基本資料',
    sidebarPassword: '變更密碼',
    sidebarToken: 'Access Token',
    sidebarAccount: '帳號管理',
    sidebarOrganization: '組織管理',
    sidebarConsumer: '運用者審核',
    sidebarDistribute: '分散式管理',
    DistributeAppManagement: '客戶端應用程式管理',
    OrgOwner: '組織擁有者',
    OrgState: '組織狀態',
    description: '備註',
    name: '名稱',
    FirstContact: '主要聯絡窗口',
    memberName: '使用者名稱',
    Contact: '聯絡窗口',
    ContactName: '聯繫人姓名',
    ContactTel: '聯繫人電話',
    email: '電子信箱',
    loginAccount: '登入帳號',
    login: '帳號',
    account: '帳號',
    PlaceName: '場域名稱',
    DataPlaceName: '資料場域名稱',
    org_city: '場域所在縣市',
    organization: '公司/組織',
    Authorities: '主管機關',
    viewOrganization: '可檢視組織',
    Id: '統編',
    orgId: '統編/代碼',
    OrgIdorNumber: '公司統編或稅籍編號',
    orgCity: '所屬縣市',
    phone: '電話',
    department: '部門',
    title: '職稱',
    role: '角色',
    state: '狀態',
    active: '啟用',
    inactive: '停用',
    pass: '通過',
    reject: '不通過',
    edit: '編輯',
    password: '密碼',
    resetPassword: '重設密碼',
    oldPassword: '舊密碼',
    newPassword: '新密碼',
    confirmNewPassword: '重新輸入密碼',
    accountInfo: '帳號基本資料',
    changePassword: '變更密碼',
    AccountRule: '帳號需要2-60個字並只接受._-特殊字元在中間',
    PasswordRule: '密碼需要8-20個字,以及至少1個大寫,1個小寫和1個數字',
    validOrgName: '請輸入組織名稱',
    validOrgOwnerName: '請輸入組織擁有者名稱',
    validOrgID: '請輸入統編',
    validLogin: '請輸入登入帳號',
    validPwd: '請輸入密碼',
    validemail: '請輸入電子信箱',
    isDeleteOrg: '確認刪除此組織？',
    orgMemberlist: '組織成員',
    orgMemberCount: '人',
    LoginCheck: '帳號檢查',
    isDeleteOrgDESC: '刪除組織時，所有成員將一併刪除',
    isDeleteMember: '確認刪除此帳號？',
    accIsOwnerDESC: '此帳號屬於組織擁有者，若要刪除，請先至組織管理將擁有者權限轉移至其他成員',
    toOrg: '前往轉移',
    member: {
      add: '新增帳號',
      edit: '編輯帳號'
    },
    organ: {
      name: '組織名稱',
      add: '新增組織',
      addOwner: '新增擁有者',
      editOwner: '編輯擁有者',
      edit: '編輯組織'
    },
    consumer: {
      ConsumerReviewDialogTitle: '審核數據運用者'
    },
    // consumer
    isDuplicate: '帳號重複',
    notDuplicate: '帳號可使用',
    reviewReject: '未通過',
    reviewPending: '未審核',
    version: '版本',
    agreeVersion: '個資同意書',
    dateApply: '申請日期',
    dateReview: '審核日期',
    LastUpdateDate: '更新日期',
    Auditing: '審核者',
    Reason: '審核說明',
    review_status: '審核',
    suggestion: '申請說明',
    // device
    inputReasonForReject: '請填寫審核不通過原因',
    editDeviceForm: '客戶端應用程式編輯',
    reviewed: '已審核',
    reviewing: '審核中',
    reviewRecord: '審核紀錄',
    reviewDescription: '審核說明',
    disabledDate: '停用日期',
    all: '全部'
  },
  Register: {
    Provider: '數據提供者',
    Consumer: '數據運用者',
    DataProvider: '數據提供者', // for en
    DataConsumer: '數據運用者', // for en
    PlatformUser: '平台使用者',
    ContactUs: '與我們聯絡',
    PersonalInfoAgree: '個資蒐集同意書',
    TremsOfUse: '使用條款',
    FetchType: '串接方式',
    FetchAPi: 'API 串接',
    FetchService: '平台網頁串接(Excel檔案上傳)',
    Suggestion: '建議事項',
    NeedHelp: '若有任何問題，請',
    ApplyFormTitle: '運動數據公益平台串接申請',
    placeholderOrgName: '請輸入同意書上簽署之資料歸屬組織',
    placeholderOrgId: '請輸入公司統編或稅籍編號',
    placeholderEmail: '請輸入電子信箱',
    placeholderphone: '請輸入電話',
    placeholderName: '請輸入使用者名稱',
    placeholderSuggestion: '請輸入建議事項',
    placeholderPlace: '請輸入資料提供場域，例如OO健康中心',
    placeholderDescripUsage: '請詳細說明資料需求及可能的公益用途',
    placeholderPlaceOrgCity: '請輸入場域所在縣市',
    placeholderContactName: '平台主要聯繫人姓名',
    placeholderContactNo: '平台主要聯絡窗口之聯繫電話',
    placeholderBelong: '例如：OO署、OO部',
    pleaseCheckAgree: '請勾選同意服務條款',
    pleaseReadAndCheck: '請詳閱《使用條款》,並勾選確認表示同意',
    RegisterNotify1: '資料審核通過後，您會於7個工作日內收到登入帳號及密碼。',
    RegisterNotify2: '若未收到通知，請與我們聯繫。',
    RegisterConsumerNotify1: '感謝申請成為',
    RegisterConsumerNotify2: '我們將盡快通知您開放日期。',
    RegisterConsumerNotify3: '若在等待的過程中有任何疑問，歡迎隨時',
    ApplySuccess: '申請成功',
    IReadAndAgree: '本人已閱讀同意平台所列蒐集目的之必要範圍內，蒐集、處理及利用本人之個人資料',
    JoinUs: '加入運動數據公益行列',
    WithUs: '與我們一起帶動運動科技產業發展，促進全民運動健康！',
    DonateData: '我想提供數據',
    UseData: '我想取得數據',
    NoAccount: '還沒有帳號?'
  },
  Organization: {
    Organization_Employer: '組織擁有者',
    Data_Consumer: '數據運用者',
    Member: '成員',
    SubAdmin: '管理者',
    Administrator: '系統管理者',
    consumer: '運用者', // for css
    provider: '提供者' // for css
  },
  activities: {
    organization_name: '公司/組織',
    user_name: '使用者',
    action_type: '動作',
    details: '內容',
    act_at: '時間',
    DELETE_DATAS: '刪除資料',
    UPLOAD_DATAS_BY_FILE: '上傳檔案(網頁)',
    UPLOAD_DATAS_BY_API: '上傳檔案(API)',
    UPLOAD_DATAS_BY_DISTRIBUTION: '上傳檔案(分散式)',
    DOWNLOAD_SDV_DATA: '下載數據',
    CREATE_ORGANIZATION: '新增組織',
    UPDATE_ORGANIZATION: '編輯組織',
    DELETE_ORGANIZATION: '刪除組織',
    CREATE_MEMBER: '新增帳號',
    DELETE_MEMBER: '刪除帳號',
    UPDATE_MEMBER: '編輯帳號',
    MEMBER_LOGIN: '登入',
    created_at: '處理時間',
    ProcessingTimes: '處理筆數',
    log: '處理紀錄',
    proces_user: '處理者'
  },
  Spec: {
    selectDataType: '選擇數據類型',
    item: '量測項目',
    columName: '欄位名稱',
    unit: '量測單位',
    required: '必填',
    nonRequired: '非必填',
    reasonalbe_data: '資料範圍',
    Example: '範例',
    desciption: '說明',
    noData: '本項目無子類型',
    all: '擇一填寫',
    true: '是',
    false: '否'
  },
  Device: {
    DeviceList: '用戶端設備清單',
    SyncMembers: '同步使用者',
    online: '在線',
    offline: '離線',
    error: '錯誤',
    integration: '傳輸中'
  },
  DeviceListField: {
    Status: '狀態',
    OrgName: '組織名稱',
    LoadingStatus: '資料傳輸',
    UpdatedTime: '最後回報時間'
  },
  Form: {
    placeholderSelect: '請選擇',
    placeholderInput: '請輸入',
    placeholderKeyword: '請輸入關鍵字'
  },
  errorMessage: {
    // Organization
    1003: '找不到ID為 {organization_id} 的組織。',
    // Member
    2001: '找不到 ID 為 {user_id} 的使用者。',
    2002: '使用者名稱 {name} 違反命名規則。只能使用大寫字母、小寫字母、數字、減號、底線、句點，且頭尾必須是字母或數字，且必須為 2 到 60 個字元。',
    2003: '密碼格式不符合規範。密碼需要為 8 到 20 個字元長，且必須包含至少一個大寫字母，一個小寫字母，以及一個數字(密碼格式可以使用a-z, A-Z, 0-9, !@#$%^&*()_+|{}[]``~-=\'";:/?.>,<)。',
    2004: '密碼或使用者帳號錯誤。',
    2005: '此帳號已有人使用。',
    2009: '該組織成員，權限不足以修改角色權限。',
    2010: '組織的擁有者不可被移除。',
    2011: '請先更換密碼。',
    2100: '此 {member_account} 已經在 15 分鐘內，登入失敗超過 5 次。', 
    2101: '此IP ( {ip} )已經被封鎖了',
    2012: '帳號或信箱錯誤',
    // Access Token
    3005: '您的權限不足已更改用戶存取權限。',
    3101: 'Access_token {access_token} 不存在。',
    3102: '已經過期了 {access_token_name}。',
    3103: '根據你的 Access token, 並沒有權限可以進行此操作。',
    3201: '過期的 Access token。',
    3202: '無效的 Access token',
    // Excel Upload
    4001: '檔案需包含以下欄位',
    4002: '檔案出現未定義的欄位',
    4003: '檔案不應該出現以下欄位',
    4004: '"紀錄日期時間" 應符合 2022-01-01 00:00:00 的格式, 且型態必須為string。',
    4005: '上傳的檔案類型必須為 CSV 或 Excel',
    4006: '檔案裡有整筆空的row',
    4007: '選擇的檔案必須為 CSV, EXCEL 或 ODS',
    4101: '資料庫不存在 {data_type} - {type} - {subtype} - {version} 的資料。',
    4102: '無法產生合成數據。',
    4201: '刪除的數據不可以超過3000筆。',
    // Common Upload Error
    5001: '該版本 {version} 被禁止或不存在。',
    5002: '該數據類型 {data_type} - {type} - {subtype} 不存在或未被定義。',
    5100: '每次上傳，資料總列數不得高於7000筆。',
    // Device
    1004: '啟用金鑰錯誤',
    7021: '裝置權限被拒絕，請聯繫管理員。',
    // Unkown Error
    9001: '非預期錯誤，請聯繫管理者。',
    9002: '無效的 Code path',
    9999: '內部發生未知錯誤，請聯繫管理者。'
  },
  DataExplore: {
    sdv_count: '數據筆數',
    download_times: '下載次數',
    dataEmpty: '本數據集目前無資料',
    downloadDialogTitle: '下載API',
    interface: '介面',
    selectedDatatype: '目前選擇',
    apiKey: 'API 參數值參考如下'
  },
  Privacy: {
    Title_Subject: '隱私權政策',
    Desc_Subject: '數位發展部數位產業署「運動數據公益平台網站」（以下簡稱本網站）為確保民眾在瀏覽本網站時的隱私權，故訂定此隱私權政策。藉以協助您瞭解本網站如何蒐集、運用及保護所提供的個人資料。請您詳讀下列內容，以促進您安心使用本網站的各項貼心服務。同時，本網站所蒐集到的個人資料，除其他法律另有規定者外，謹遵守「個人資料保護法」之規範辦理。',
    Title_Section1: '一、適用範圍',
    Desc_Section1: '以下隱私權政策，適用於您在本網站活動時，所涉及的個人資料蒐集、運用與保護，但不適用於與本網站功能連結之各政府機關網站與繳費網站。凡經由本網站連結之網站，各網站均有其專屬之隱私權政策，本網站不負任何連帶責任。當您連結這些網站時，關於個人資料的保護，適用各該網站的隱私權政策。',
    Title_Section2: '二、平台之個人資料蒐集、處理及利用',
    Desc1_Section2: '蒐集目的：本平台為進行使用者之身分認證與管理，依個人資料保護法之特定目的及個人資料之類別規定之一三五「資（通）訊服務」、一三六「資（通）訊與資料庫管理」、一三七「資通安全與管理」、一五七「調查、統計與研究分析」等蒐集您的個人資料。',
    Desc2_Section2: '個人資料蒐集類別：識別個人之資料，例如姓名、電子郵件地址、電話或手機等。',
    Desc3_Section2: '個人資料使用期間、地區、對象',
    Desc3_1_Section2: '(1) 期間：個人資料蒐集之特定目的存續期間、依相關法令規定或契約約定之保存年限，及本平台因執行職務或業務所必須之保存期間。',
    Desc3_2_Section2: '(2) 地區：中華民國行政區域內。',
    Desc3_3_Section2: '(3) 對象：本平台營運管理機關，以及營運相關事務之必要第三方機關（構）。',
    Desc3_4_Section2: '(4) 單純在本平台的瀏覽及檔案下載行為，本平台並不會蒐集任何有關個人的身份資料。',
    Desc3_5_Section2: '(5) 本平台系統會保留您在瀏覽或查詢時的相關紀錄，包括使用時間、瀏覽器及瀏覽點選資料紀錄等，這些資料係供本平台管理單位內部作網站流量和網路行為調查的總量分析，以利於提昇本平台的服務品質，並不會對個別使用者進行分析。',
    Title_Section3: '三、與第三方共用個人資料',
    Desc1_Section3: '本平台絕不會提供、交換、出租或出售任何您的個人資料給其他團體、個人或私人企業。於利用您的個人資料時，均與蒐集之特定目的相符，但有下列情形之一者，得為特定目的外之利用：',
    Desc1_1_Section3: '(1) 經由您的同意。',
    Desc1_2_Section3: '(2) 法律明文規定。',
    Desc1_3_Section3: '(3) 為維護國家安全或增進公共利益。',
    Desc1_4_Section3: '(4) 為免除您的生命、身體、自由或財產上之危險。',
    Desc1_5_Section3: '(5) 公務機關或學術研究機構基於公共利益為統計或學術研究而有必要，且資料經過提供者處理後或蒐集者依其揭露方式無從識別特定之當事人。',
    Desc1_6_Section3: '(6) 當您在本平台的行為，違反本平台的服務條款或可能損害或妨礙本平台權益或導致任何人遭受損害時，經本平台研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。',
    Desc1_7_Section3: '(7) 有利於當事人權益。',
    Desc2_Section3: '本平台委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。',
    Title_Section4: '四、當事人得行使之權利及方式',
    Desc1_Section4: '當您在本服務中註冊成為使用者後，您可以隨時利用您的帳號和密碼登入使用者專區，對您填寫的使用者個人資料，進行查閱、製給複製本、補充、更正、刪除，及停止蒐集、處理或利用之要求。請注意，在您選擇刪除所註冊之使用者帳號後，您將無法再以該帳號登入本平台使用者服務，其餘個人資料權利之行使，您可透過電話或線上申請方式向本平台提出。',
    Desc2_Section4: '以下情形本平台得保留當事人行使刪除或停止蒐集、處理、利用之要求：',
    Desc2_1_Section4: '(1) 公務機關執行法定職務或業務所必須。',
    Desc2_2_Section4: '(2) 其他不能刪除或停止蒐集、處理、利用之正當事由。',
    Title_Section5: '五、Cookie的運用',
    Desc1_Section5: 'Cookie是資訊系統為管理需求及提供最佳個人化服務，經由瀏覽器寫入使用者硬碟的簡短資訊。本平台會在您瀏覽器中寫入並讀取Cookie，您可以在本平台操作說明中可瞭解如何設定接受或拒絕Cookie，如果您選擇拒絕，並不會影響一般的網站瀏覽服務，但可能會導致網站某些功能無法正常執行。',
    Desc2_Section5: '上述之Cookie僅會對個別連線者的瀏覽器予以標示，記錄瀏覽器在本平台內部所瀏覽的網頁，進行瀏覽人數統計及瀏覽模式分析，做為本平台改善服務的參考，但不會識別特定當事人，若有基於公共利益為統計或學術研究而有必要，資料亦將經過處理，使其揭露方式無從識別特定之當事人。',
    Title_Section6: '六、自我資料安全保護措施',
    Desc_Section6: '請妥善保管您個人資料，切勿將個人資料提供給任何第三人，以保障您的權益。若您是與他人共享電腦或使用公共電腦，使用完畢請務必記得登出帳號，並關閉本平台視窗。',
    Title_Section7: '七、隱私權保護政策修訂',
    Desc_Section7: '本隱私權政策若有修訂，將公告於本平台，請您隨時注意修訂之公告訊息。',
    Title_Section8: '八、隱私權保護政策之諮詢（聯絡窗口）',
    Desc_Section8: '若您對本條款有任何疑問，歡迎您隨時，歡迎隨時'
  },
  TermsOfService: {
    Title_Subject: `運動數據公益平台服務條款`,
    Desc_Subject: `歡迎您使用「運動數據公益平台」（以下簡稱本平台）！本平台是由數位發展部數位產業署委託財團法人資訊工業策進會（以下簡稱管理單位）建置、運作及管理，以帶動國內數據之公益運用。`,
    Title_Section1: `一、運動數據公益平台隱私權政策`,
    Desc_before_link_Section1: `本平台重視每位使用者的隱私權，您了解並同意使用本平台服務時，關於您的使用者註冊以及其他特定資料，將依`,
    Desc_after_link_Section1: `保護與規範，蒐集、處理、利用您的個人資料，希望您在使用本平台服務前，能夠詳細閱讀服務條款及隱私權政策。`,
    Title_Section2: `二、對於條款的同意表示與條款變更`,
    Desc1_Section2: `本平台依據服務條款提供各項服務，當您註冊完成或開始使用本平台服務時，即表示您已閱讀、了解並同意接受服務條款的所有內容，並完全接受本平台現有與未來衍生的服務項目及內容。`,
    Desc2_Section2: `若您無法確實遵守條款內容，或對於本條款內容之全部或部分內容不同意時，或是您所屬之國家或地域不適用本條款內容之全部或部分時，您應立即停止使用本平台服務。`,
    Desc3_Section2: `本平台有權於任何時間修改或變更本平台服務條款之內容，並公告於本平台，請您隨時注意該等修改或變更。若您於任何修改或變更後繼續使用本平台服務，則視為您已閱讀、了解並同意接受該等修改或變更。 `,
    Title_Section3: `三、定義`,
    Desc1_Section3: `服務：指由本平台網站、應用程式所提供之各式支援服務。`,
    Desc2_Section3: `平台使用者：指瀏覽或使用本平台網站服務者。`,
    Desc3_Section3: `數據提供者：指串接本平台，並上傳運動數據者。`,
    Desc4_Section3: `數據運用者：指基於數據公益理念與促進社會發展、運動科技產業發展之相關用途，並下載運動數據進行收集、處理、利用者。`,
    Title_Section4: `四、平台使用者串接申請`,
    Desc_Section4: `若您想申請成為本平台之數據提供者或數據運用者，針對串接測試、正式上線、異動或中止介串接，應依下列規定辦理：`,
    Desc1_Section4: `應配合測試環境串接測試，於收到正式串接通知後，得進行串接異動或中止串接。`,
    Desc2_Section4: `已中止串接者得依前款規定申請恢復串接。`,
    Desc3_Section4: `申請人應配合管理單位進行測試，並依本平台相關規範及格式辦理串接。`,
    Title_Section5: `五、平台使用者應遵守的事項`,
    Desc1_Section5: `您成為數據提供者或數據運用者後，應遵守下列事項`,
    Desc1_1_Section5: `(1) 若您是數據提供者，於蒐集個人資料時，應確實取得資料當事人之同意，並保存紙本或電子形式。`,
    Desc1_2_Section5: `(2) 若您是數據運用者，僅得於申請串接之範圍及目的內運用數據，不得以任何方式重新識別該數據。`,
    Desc1_3_Section5: `(3) 數據提供者及數據運用者皆應留存數據傳輸相關紀錄至少六個月。紀錄內容至少應包含數據欄位名稱、傳輸時間、傳輸對象、數據傳輸成功與否等。管理單位將視情況辦理查核。`,
    Desc2_Section5: `平台使用者串接本平台並進行數據運用，需自行確保其所開發產品或服務之品質及正確性。如有違法情事，致當事人權益受損害者，須負擔相關法律責任。`,
    Desc3_Section5: `您了解並同意您在本平台上所有之行為，必須符合本服務條款及相關法令規定，並應自行負擔全部風險與法律責任。您若係中華民國以外之使用者，並同意遵守所屬國家或地域之相關法規。`,
    Desc4_Section5: `若發生數據外洩、遭竊、遭竄改或遺失，及發現數據有侵害他人隱私權或其他權利之虞，數據提供者及數據運用者應立即停止串接並向管理單位通報。`,
    Desc5_Section5: `違反本服務條款其他規定者，管理單位得要求限期改善；如未於期限內改善或違反情節重大時，管理單位得中止其串接服務。`,
    Title_Section6: `六、服務變更及通知`,
    Desc1_Section6: `本平台已盡可能彙整最新資訊提供使用者瀏覽。惟本網站提供服務之形式及內容將依業務推展及使用者需求，得事先不經通知使用者而隨時增加、修改或終止相關服務。`,
    Desc2_Section6: `您同意本平台保留於任何時間點，隨時修改、暫時或永久停止繼續提供本平台（或其任一部分）的權利。如依法或其他相關規定須為通知時，本平台得於本平台服務網頁、電子郵件，或其他合理方式通知您。但若您違反本平台服務條款，以未經授權的方式存取本平台資料，或於註冊時所提供之資料不正確或未更新，您將不會收到前述通知。`,
    Title_Section7: `七、免責聲明`,
    Desc1_Section7: `本平台有時可能會出現中斷或故障等現象，或許將造成您使用上的不便或錯誤等情形。您使用本平台時宜自行採取防護措施。本平台對於您因使用（或無法使用）本平台服務而造成的損害，不負任何賠償責任。`,
    Desc2_Section7: `本平台所提供之各項功能、資訊及內容，均依該功能、資訊及內容當時之現況提供使用，其完整性、可靠性、安全性、正確性均由該服務之原始提供機關（構）負責，本平台對其不負任何明示或默示之擔保責任。`,
    Desc3_Section7: `您使用本平台時，使用過程中所有的資料紀錄，以本平台系統所記錄之資料為準，如有任何糾紛或訴訟，以本平台系統所記錄之資料為認定標準，您若能提出其他資料並證明為真實者，則不在此限。`,
    Title_Section8: `八、諮詢服務（聯絡窗口）`,
    Desc1_Section8: `若您對本服務條款有任何疑問，歡迎您隨時與我們聯絡。`,
    Desc2_Section8: `電子郵件信箱：service@data-sports.tw`,
    Title_Section9: `九、其他`,
    Desc_Section9: `因法令、技術、市場發展或政府政策等考量，管理單位得修改服務條件、暫停或停止本平台服務。`
  },
  InfoaAree: {
    Title_Subject: `蒐集個人資料告知事項暨個人資料提供同意書`,
    Desc_Subject: `財團法人資訊工業策進會因執行數位發展部數位產業署「運動數據公益平台推動計畫」，運作運動數據公益平台(下稱本平台)，為遵守個人資料保護法令及平台隱私權政策，於向您蒐集個人資料前，依法向您告知下列事項，敬請詳閱。`,
    Title_Section1: `一、蒐集目的及類別`,
    Desc1_Section1: `本平台因執行計畫而需獲取您下列個人資料類別：公司名稱、電子郵遞地址、聯絡人姓名、電話或手機等。`,
    Desc2_Section1: `※您日後如不願再收到本平台所寄送之行銷訊息，可於收到前述訊息時，直接點選訊息內拒絕接受之連結。`,
    Title_Section2: `二、個人資料利用之期間、地區、對象及方式`,
    Desc_Section2: `除涉及國際業務或活動外，您的個人資料僅供本平台於中華民國領域、在前述蒐集目的之必要範圍內，以合理方式利用至蒐集目的消失為止。`,
    Title_Section3: `三、當事人權利`,
    Desc_Section3: `您可依前述業務、活動所定規則或以e-mail方式向本平台服務信箱(service@data-sports.tw)提出申請行使下列權利：`,
    Desc1_Section3: `查詢或請求閱覽。`,
    Desc2_Section3: `請求製給複製本。`,
    Desc3_Section3: `請求補充或更正。`,
    Desc4_Section3: `請求停止蒐集、處理及利用。`,
    Desc5_Section3: `請求刪除您的個人資料。`,
    Title_Section4: `四、不提供個人資料之權益影響`,
    Desc_Section4: `若您未提供正確或不提供個人資料，本平台將無法為您提供蒐集目的之相關服務。`,
    Title_Section5: `五、您瞭解此一同意書符合個人資料保護法及相關法規之要求，且同意本平台留存此同意書，供日後取出查驗。`,
    Desc_Section5: `個人資料之同意提供`,
    Desc1_Section5: `本人已充分獲知且已瞭解上述告知事項。`,
    Desc2_Section5: `本人同意平台所列蒐集目的之必要範圍內，蒐集、處理及利用本人之個人資料。`
  },
  Banner: {
    Accumulated_Data: `可用數據累積`,
    Accumulated_Data_Content: `「數據公益」(Data Altruism, 資料利他主義)是來自歐盟近年所研議的 《資料治理法》(The Data Governance Act, DGA)，透過民眾對政府的信任， 將數據貢獻出來，以利公共利他的研究，協助國內運動科技產業賦能， 以社會創新帶動產業創新。`,
    Sport_Data: `運動數據`,
    Physical_Fitness_Data: `體適能數據`,
    Physiology_Data: `生理數據`
  },
  Introduce: {
    Data_Specification: `數據規格介紹`,
    Data_Specification_Content: `我們與運動數據專家共同定義運動數據標準，透過一致的數據規格來共享數據，這樣可以讓不同的數據來源有彼此串接的機會。我們目前已定義出了數據規格，可以接受至少65種不同的數據類型，包括棒球、羽毛球、跑步、健行、健身、體適能及生理數據。歡迎點擊連結進入我們的規格頁面，進一步了解平台的數據規格和共享流程。`
  },
  Dataset: {
    Download: '下載數據集說明',
    DownloadFile: '檔案下載',
    DownloadCount: '下載次數',
    ViewCount: '查看次數',
    Column: '主要欄位',
    DatasetTitle: '數據集',
    Dataset: '數據集',
    DatasetInfo: '數據資訊',
    LoginWithConsumer: '請先以數據運用者身份登入',
    Running_Heart_Rate: `跑步心率數據集`,
    Running_Heart_Rate_Description: `收集經電子業員工知情同意且去識別無個資之跑步數據。`,
    Running_Heart_Rate_Measure: `心率、時速、性別、年齡`,
    Swimming_Rate: `游泳25/50公尺數據集`,
    Swimming_Rate_Description: `收集經游泳愛好者知情同意且去識別之標準泳池運動數據。`,
    Swimming_Rate_Measure: `滑水次數、划距、划頻、配速、游泳效率、趟數`,
    Outdoor_Biking_Rate: `戶外騎車數據集`,
    Outdoor_Biking_Rate_Description: `收集經使用者知情同意公益使用之戶外騎車各項數據。`,
    Outdoor_Biking_Rate_Measure: `經緯度、速度、距離、總上升海拔、總下降海拔、最大速度、回轉數`,
    Indoor_Exercise_Rate: `室內運動數據集`,
    Indoor_Exercise_Rate_Description: `收集經使用者知情同意公益使用之居家運動數據。`,
    Indoor_Exercise_Rate_Measure: `時段、運動時間、課程名稱、性別、年齡`
  },
  Platform: {
    Platform_Policy: `平台政策背景`,
    Policy_Basis: `政策依據`,
    Policy_Basis_Section1: `依據運動×科技SRB會議結論，跨部會整合規劃5年(1+4)「運動科技應用與產業發展計畫」及四大戰略，期藉由各部會協力合作，帶動整體運動科技產業發展及促進全民運動健康。`,
    Policy_Basis_Section2: `計畫戰略期望透過數位部發展共通的運動資料蒐集和跨部會合作治理的新基礎建設，並串連衛福部、教育部、國科會、經濟部等部會上下游場域資源。`,
    Vision_Platform: `運動數據公益平台願景`,
    Vision_Platform_Section1: `打造分散式運動資料合規平台，好用、好取、易加值公益運動服務生態`,
    Vision_Platform_Section2: `藉由政府資料治理機制，保護個人資料自主性，解決資安與隱私顧慮。加強公民對政府的資料信任，進而願意共享其在運動賽事、國民運動中心、各級體育場館、社區生活運動場域累積的資料。建立一個以開放共享的運動數據公益健康生態系。`,
    Sports_Everywhere: `連結 : 台灣運動×科技行動計畫—Sports Everywhere`,
    Personal_info: `針對個人資料保護法之作法`,
    Personal_info_Section1_1: `運動數據公益平台之數據提供者需提供個資當事人簽署「`,
    Personal_info_Section1_2: `」，明確告知當事人運動資料上傳至運動數據公益平台，並供公部門或私部門推動公益事務之參考及運用，以符合個資法第5條及第19條規定。運動數據在取得個資當事人同意，並數據經隱私強化程序後始串接至平台。 `,
    Personal_info_Section2_1: `另平台為確保數據提供者之個資保護管理機制，依據個資法施行細則第12條提供「`,
    Personal_info_Section2_2: `」，由數據提供者自我評估個資保護落實情況，並針對數據提供者個資保護管理流程進行查核，提供符合度建議，以協助數據提供者完善個資保護作業。`,
    Personal_info_Section3_1: `詳情請參閱「`,
    Personal_info_Section3_2: `」。`
  },
  DataUsage: {
    Data_Usage: `資料使用說明`,
    Foreword: `前言`,
    Foreword_Description: `藉由數據公益平台(適法、合規)，產生利於研究的資料資料運用模式。帶動國內場館、運動科技業者，共同串聯、貢獻資料以產生利他新應用。`,
    How_and_Who: `如何收、誰來用`,
    How_and_Who_Section1: `整體將分為三個角色，資料的貢獻方、資料的蒐集方，以及資料的使用方。貢獻方可為個人或企業。蒐集方包括公、民營運動場館單位、運動活動主辦方、運動APP業者、運動設備業者等等。使用方包括政府機構、民間單位、學研界等。`,
    How_and_Who_Section2: `資料流程由蒐集方開始，向貢獻方(個人/企業)提出《蒐集個人資料告知同意書》，取得個人資料授權。蒐集方取得授權後的資料開始自行去識別、去連結後，讓這些蒐集方保有的資料與運動數據公益平台串接。平台可呈現資料，讓使用方能夠在平台中檢視。使用方可向廠商取得資料使用授權，提出資料的公益運用，結果將回饋提升公共利益或者產生有助於民眾運動的服務。`,
    Data_Usage_Include: `數據公益用途可包含但不限於以下：`,
    Exercise_Advocacy: `研析運動倡議項目`,
    Studies_and_Analyses: {
      Road_Section: `運動族群路段分析`,
      Time_Slot: `運動族群時段分析`,
      Urban_rural: `城鄉數位落差`,
      Exercise_Energy: `研析國內運動能量`,
      Tourism_Festival: `運動觀光季研擬規劃`,
      Fatigue_Recuperation: `賽後運動疲勞恢復分析`,
      Sports_Science: `運動科學分析訓練`
    },
    Integrating_Exterior_Data: `結合外部開放資料`,
    Integrating_Exterior: {
      Routes: `結合運動友善路徑`,
      Shops: `結合運動友善店家`
    }
  },
  Navigator: {
    Site_Navigator: '網站導覽',
    Site_Navigator_Include: '本網站依「無障礙網頁設計原則」建置，包括：',
    Site_Navigator_Include_Section: {
      Section1: `所有的頁面都可以用鍵盤操作，不限於使用滑鼠。`,
      Section2: `網站設置導盲磚快速鍵（:::），使用者可快速到達各主要區塊。`,
      Section3: `網頁圖片均標示適當的替代文字說明。`,
      Section4: `在介面變更、變更網址或開新視窗的連結項目上加上提示文字，使用者不需一一進入連結頁面即可知道每一個連結的目的。`
    },
    Site_Navigator_Description: '本網站主要內容分為三大區塊',
    Site_Navigator_Block: {
      Upper: `上方功能區塊`,
      Center: `中央內容區塊`,
      Lower: `下方功能區塊`,
      Left: `左方功能區塊`
    },
    Site_Navigator_Shortcut: {
      AltU: `上方功能區塊，包括回首頁、網站導覽、開發指引等。`,
      AltC: `中央內容區塊，為本頁主要內容區。`,
      AltL: `左方功能區塊，為本站內頁〔次要選單〕此區域僅在部分內頁出現。`,
      AltZ: `下方功能區塊。`
    },
    Browser: `如果您的瀏覽器是Firefox，快速鍵的使用方法是 Shift+Alt+（快速鍵字母），例如 Shift+Alt+C會跳至網頁中央內容區塊，以此類推。`
  },
  FAQ: {
    Q9: '運動數據公益平台法遵相關資訊可在哪裡檢視呢?',
    Q9_Answer: `請參考運動數據公益平台個資評估法遵使用手冊。`
  },
  FAQ_Provider: {
    Eligibility: '關於資格',
    About_Eligibility: {
      Q1: `誰可以貢獻數據？`,
      Q1_Answer: `平台歡迎所有有意願與平台合作的公司團體提供數據。`,
      Q2: `運動數據公益平台的收費機制是什麼？`,
      Q2_Answer: `平台目前對於數據提供者而言是免費使用。`
    },
    Compliance: '關於合規',
    About_Compliance: {
      Q1: `作為數據提供者，我提供的數據需要事先取得個人知情同意嗎？`,
      Q1_Answer: `所有數據提供者提供的數據必須先取得個人個資同意書，並保留備查。`,
      Q2: `個資同意書需要包含哪些內容？`,
      Q2_Answer1: `請參考個資同意書範本。`,
      Q2_Answer2: `點此下載`,
      Q3: `如何取得並保存個資同意書？`,
      Q3_Answer: `為了要確認確實有經過資料當事人同意，實務上通常會透過簽署個資同意書之方式，並留存書面/電子檔案作為證明。`,
      Q4: `我是平台的合作場域廠商，個資同意書需要親筆簽名嗎? 還是可以使用個人載具進行簽署？`,
      Q4_Answer: `個資同意書其實沒有一定的形式，但需要保存證據，如果相關APP有紀錄存在就可以，不一定需要親筆簽名。`
    },
    Field_Specification_and_Data_Quality: '數據規格與資料品質',
    About_Field_Specification_and_Data_Quality: {
      Q1: `什麼是數據規格？為什麼上傳要準備規格？`,
      Q1_Answer: `數據規格的設置是為了讓大家貢獻的數據能夠有一致的格式，以利後續各方可以串接使用，因此所有貢獻之數據均需遵守平台訂定之數據規格。`,
      Q2: `哪裡可以看得到最新版的數據規格？`,
      Q2_Answer1: `查看`,
      Q2_Answer2: `數據規格`,
      Q3: `我的量測項目如果不在數據規格內，該如何提出新的上傳規格？`,
      Q3_Answer1: `首先請向`,
      Q3_Answer2: `提出，平台內部會安排會議與專家討論是否依據建議做調整，也歡迎推薦專家一起參與討論，若有更新則在下一個運動數據規格的版本中釋出。`,
      Q3_File_name: `欄位規格新增申請文件`,
      Q3_mailto: `平台信箱`,
      Q4: `量測項目的格式需要轉換成數據規格所定義的型態嗎？`,
      Q4_Answer: `上傳資料請依請以平台提供的規格為主，因平台進行二階段合規檢查，雖然第一階段可能成功上傳，但第二階段還是會被檢查出並剔除，所以建議從一開始上傳資料就符合完整規格。`,
      Q5: `使用者出生年要是西元年還是民國年？`,
      Q5_Answer1: `平台定義的日期格式為西元年。`,
      Q5_Answer2: `請提供者協助自行將民國年轉換到西元後，再上傳到運動公益平台上。`,
      Q6: `活動名稱應該要寫什麼？`,
      Q6_Answer: `活動名稱為非必填欄位，因此可依據場域規劃自由填入。`,
      Q7: `記錄時間的格式是什麼？`,
      Q7_Answer1: `紀錄時間主要是該筆資料紀錄時的時間，如為一個時間區段，則以起始時間即可。`,
      Q7_Answer2: `日期格式為 2022-01-01 00:00:00。`,
      Q8: `一筆資料的定義是甚麼？`,
      Q8_Answer: `平台定義一行包含數據類型、數據基本資料及量測項目的資料為一筆資料。`,
      Q9: `若欄位沒有資料，該表格要空白、給空值還是直接不填？`,
      Q9_Answer: `只要是非必填的欄位，欄位的值給空值或是沒有資料都是可以的，但鼓勵增加所填資料的完整性。`
    },
    API_Concatenation: 'API串接',
    About_API_Concatenation: {
      Q1: `如何使用API串接數據？`,
      Q1_Answer1: `平台提供每個使用者一組專屬API token，使用者可依專屬API token，搭配數據規格及 Open API 進行數據串接。`,
      Q1_Answer2: `如需API串接技術服務，可向平台聯繫。`,
      Q2: `使用API串接有甚麼好處？`,
      Q2_Answer: `透過API串接可省下您的時間，並且可以更有效率的整合使用者資料，可大幅降低數據共享前所需投入的時間成本。`
    },
    Data_Security: '關於資安',
    About_Data_Security: {
      Q1: `平台收集使用者的個資嗎？`,
      Q1_Answer1: `為了日後數據的統計分析，鼓勵提供完整資料，基本資料僅需要年齡(出生年月日)，並不收集其他個資。`
    },
    Management: '關於管理',
    About_Management: {
      Q1: `作為數據提供者，管理上有哪些權限？`,
      Q1_Answer1: `數據提供者目前可上傳、刪除、下載屬於自己帳號的所有數據。`
    }
  },
  JoinUs: {
    Welcome: `歡迎加入我們`,
    Slogan1: `用數據探索公益的可能性，攜手創造更多價值`,
    Slogan2: `想知道更多訊息`,
    Slogan3: `將數據轉化為公益價值，共創美好未來`,
    Slogan4: `加入我們，讓運動數據為公益盡一份心力！`
  },
  City: {
    基隆市: `基隆市`,
    新北市: `新北市`,
    臺北市: `臺北市`,
    台北市: `台北市`,
    桃園市: `桃園市`,
    新竹縣: `新竹縣`,
    新竹市: `新竹市`,
    苗栗縣: `苗栗縣`,
    臺中市: `臺中市`,
    台中市: `臺中市`,
    彰化縣: `彰化縣`,
    南投縣: `南投縣`,
    雲林縣: `雲林縣`,
    嘉義縣: `嘉義縣`,
    嘉義市: `嘉義市`,
    臺南市: `臺南市`,
    台南市: `臺南市`,
    高雄市: `高雄市`,
    屏東縣: `屏東縣`,
    宜蘭縣: `宜蘭縣`,
    花蓮縣: `花蓮縣`,
    臺東縣: `臺東縣`,
    台東縣: `臺東縣`,
    澎湖縣: `澎湖縣`,
    金門縣: `金門縣`,
    連江縣: `連江縣`,
    綠島: `綠島`,
    蘭嶼: `蘭嶼`,
    馬祖: `馬祖`,
    其他: `其他`,
    未填: `未填`
  },
  ContactUs: {
    Tel: '連絡電話',
    Email: '電子信箱'
  }
}
