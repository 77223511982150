<template>
  <div class="consumer-header">
    <el-row>
      <div class="nav-desk">
        <el-col :span="8">
          <LogoWithLinked />
        </el-col>
        <el-col :span="16" class="navbar-item-container">
          <el-menu
            mode="horizontal"
            background-color="#066471"
            text-color="#d9d9d9"
            active-text-color="#ffffff"
            @select="handleSelect"
          >
            <template
              v-for="(item, index) in consumerMenu"
            >
              <el-menu-item
                v-if="item.type === 'router' && item.device !== 'pad' && (item.routerName !== 'SdcampLogin' || (item.routerName === 'SdcampLogin' && language === 'zh-TW'))"
                :key="index"
                :index="item.id"
                :style="item.style"
              >
                <router-link
                  :to="{ name: item.routerName }"
                >
                  {{ $t(`navbar.${item.displayName}`) }}
                </router-link>
              </el-menu-item>
              <el-submenu
                v-else-if="item.type === 'subMenu'"
                :key="index"
                :index="item.id"
              >
                <template slot="title">
                  {{ $t(`navbar.${item.displayName}`) }}
                </template>
                <el-menu-item 
                  v-for="(childItem, childIndex) in item.children"
                  :key="childIndex"
                  :index="childItem.id"
                  :style="childItem.style"
                >
                  <div
                    v-if="childItem.type === 'outerLink' "
                    :key="childIndex"
                  >
                    <a 
                      :href="childItem.link"
                      :aria-label="$t(`Aria.${childItem.ariaLabel}`)"
                      target="_blank"
                    >
                      {{ $t(`navbar.${childItem.displayName}`) }}
                    </a>
                  </div>
                  <router-link v-else :to="{ name: childItem.routerName }">
                    {{ $t(`navbar.${childItem.displayName}`) }}
                  </router-link>
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                v-else-if="item.type === 'component'"
                :key="index"
                :index="item.id"
                :style="item.style"
              >
                <component :is="item.component" />
              </el-menu-item>
            </template>
          </el-menu>
        </el-col>
      </div>
      <!-- hambuger -->
      <div class="nav-pad">
        <el-col :span="16">
          <LogoWithLinked />
        </el-col>
        <el-col :span="8" class="navbar-menu">
          <el-row>
            <el-col :span="6">
              <el-button
                :class="['btn-hamburger', styleConsumer ]"
                type="text"
                icon="el-icon-s-fold"
                size="medium"
                @click="drawer = true"
              />
              <el-drawer
                :visible.sync="drawer"
                :direction="direction"
                :with-header="false"
                custom-class="rwd-menu"
              >
                <el-menu 
                  :default-active="activeMenu" 
                  class="el-menu-vertical-demo"
                  text-color="#606266"
                  active-text-color="#0c356d"
                  mode="vertical"
                  :collapse="true"
                  @select="handleSelect"
                >
                  <template
                    v-for="(item, index) in consumerMenu"
                  >
                    <el-menu-item
                      v-if="item.type === 'router' && item.device != 'desk' && (item.routerName !== 'SdcampLogin' || (item.routerName === 'SdcampLogin' && language === 'zh-TW'))"
                      :key="index"
                      :index="item.id"
                      :style="item.style"
                    >
                      <router-link
                        :to="{ name: item.routerName }"
                      >
                        {{ $t(`navbar.${item.displayName}`) }}
                      </router-link>
                    </el-menu-item>
                    <el-submenu
                      v-else-if="item.type === 'subMenu'"
                      :key="index"
                      :index="item.id"
                    >
                      <template slot="title">
                        {{ $t(`navbar.${item.displayName}`) }}
                      </template>
                      <el-menu-item 
                        v-for="(childItem, childIndex) in item.children"
                        :key="childIndex"
                        :index="childItem.id"
                        :style="childItem.style"
                      >
                        <div
                          v-if="childItem.type === 'outerLink' "
                          :key="childIndex"
                        >
                          <a 
                            :href="childItem.link"
                            :aria-label="$t(`Aria.${childItem.ariaLabel}`)"
                            target="_blank"
                          >
                            {{ $t(`navbar.${childItem.displayName}`) }}
                          </a>
                        </div>
                        <router-link v-else :to="{ name: childItem.routerName }">
                          {{ $t(`navbar.${childItem.displayName}`) }}
                        </router-link>
                      </el-menu-item>
                    </el-submenu>
                  </template>
                </el-menu>
              </el-drawer>
            </el-col>
            <el-col :span="6">
              <LangSelect />
            </el-col>
            <el-col :span="12">
              <UserAccount />
            </el-col>
          </el-row>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MemberRole } from '@/Mixins'
import consumerMenu from '@/assets/siteMap/consumerMenu.json'
import LangSelect from '@/components/LangSelect'
import UserAccount from '@/components/UserAccount'
import LogoWithLinked from './LogoWithLinked.vue'

export default {
  name: 'ConsumerNavbar',
  components: {
    LangSelect, 
    UserAccount,
    LogoWithLinked
  },
  mixins: [MemberRole],
  data () {
    return {
      consumerMenu: [],
      drawer: false,
      direction: 'rtl'
    }
  },
  computed: {
    ...mapGetters(['activeMenu', 'language']),
    styleConsumer() {
      return this.isConsumer ? 'consumer-btn-hamburger' : ''
    }
  },
  created() {
    this.setActiveMenu(this.$store.getters.activeMenu)
  },
  mounted() {
    this.setMenu()
  },
  methods: {
    setMenu() {
      this.consumerMenu = consumerMenu
    },
    setActiveMenu(route) {
      this.$store.dispatch('app/setActiveMenu', route)
    },
    handleSelect(key, keyPath) {
      this.setActiveMenu(key)
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/index.scss";

.consumer-header {
  background-color: $bg_header_footer_consumer;
  position: relative;
  padding: 0 20px;

  .website-title{
    cursor: pointer;
    margin: initial;
    width: 210px;
  }
  .navbar-item-container{
    float: right;
  }

  .nav-pad{
    display: none;
  }
  h1{
    text-align: initial;
    margin: 0;
    line-height: 60px;
    padding-left: 15%;
    font-size: 24px;
    letter-spacing: 1.5px;
    color: $text_light;
  }

  .el-menu {
    float: right;
    
    .el-menu-item.is-active {   
      i {
        color: #909399;
      }
    }

    .el-menu-item:hover {
      font-weight: bold;
      border-bottom: 2px solid $theme !important;
      background-color: $bg_header_footer_consumer !important;
    }

    .el-submenu__title:hover {
      font-weight: bold;
      border-bottom: 2px solid $theme !important;
      background-color: $bg_header_footer_consumer !important;
    }
    .el-menu-item.langSelect-col{
      padding: 0;
    }

    .el-dropdown{
      color: $text_light;
    }
  }

  .el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    font-weight: bold;
  } 

  @media (max-width: 1200px) { 
    .nav-desk{
      display: none;
    }
    .nav-pad{
      display: block;

    .rwd-menu{
      .sdcamp-style{
        color: #F56C6C
      }
      .el-menu .el-menu-item{
        padding: 0;
      }
      .el-submenu{
        .el-menu-item{
          padding-left: 30px !important;
        }
      }
      
      position: absolute;
      top:60px;
      ul{
        width: 100%;
        text-align: left;
      }
    }
      .navbar-menu{
        height: 60px;
        line-height: 60px;

        .btn-hamburger{
          border: none;
          background-color: transparent;
          i{
            color: #606266;
          }
        }
        .consumer-btn-hamburger{
          i{
            color: $text_light;
          }
        }
        .el-dropdown{
          color: $text_light;
        }
      }
    }
  }
}
</style>
